var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "list-items"
  }, [!_vm.userHasRole('vendors-viewer') ? _c('div', {
    staticClass: "list-items-actions"
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    attrs: {
      "to": {
        path: '/admin/add-vendor'
      },
      "type": "info",
      "icon": "md-add"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.new')) + " ")])], 1) : _vm._e()]), _c('div', [_c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FilterTable', {
    attrs: {
      "name": "vendors",
      "container": true,
      "fetch-items": this.vendorGetVendors,
      "export-csv": _vm.exportCsv,
      "fetch-items-params": {
        category: true,
        country: true
      },
      "namespace": "vendor",
      "columns": _vm.columns,
      "limit": 30,
      "search-filters": ['search', 'show-category-exemption']
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }