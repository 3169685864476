<template>
  <div>
    <div class="list-items">
      <div
        class="list-items-actions"
        v-if="!userHasRole('vendors-viewer')"
      >
        <Button
          :to="{path: '/admin/add-vendor'}"
          type="info"
          class="btn btn--elevated"
          icon="md-add"
        >
          {{ $t('global.vendor.new') }}
        </Button>
      </div>
    </div>
    <div>
      <Row>
        <Col span="24">
          <FilterTable
            name="vendors"
            :container="true"
            :fetch-items="this.vendorGetVendors"
            :export-csv="exportCsv"
            :fetch-items-params="{category: true, country: true}"
            namespace="vendor"
            :columns="columns"
            :limit="30"
            :search-filters="['search', 'show-category-exemption']"
          />
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilterTable from '@/components/ui/FilterTable'
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'

export default {
  name: 'AdminVendorsAdmin',
  components: {
    FilterTable
  },
  mixins: [
    pageConfig,
    tableColumn
  ],
  data () {
    return {
      loading: true,
      modal: false,
     
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendors: 'vendor/getVendors',
      exportCsv: 'vendor/exportCsv'
    }),
    doesHelperColumn () {
      const { showCategoryHelper } = this
      return showCategoryHelper 
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          width: 250,
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => h('Poptip', {
            props: {
              content:params.row.category.description,
              placement:"right",
              'word-wrap': true,
              style:{wordWrap: 'break-word'},
              width: 300,
              trigger:'hover'
            }
          }, [
            h('Badge', {
              slot:"title",
              props: {
                color: this.complianceColor[this.vendorVigilanceToLetter(params.row.category.vigilance_id)],
                text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.category.vigilance_id) + (params.row.category.is_exempted ? '  - Exempted' : '')
              }
            },),
            // <i class="fas fa-check-double"></i>
            params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category.name
                
              ])
              : h('span', {}, params.row.category.name)
          ])
        }
        : { 
          title: this.$t('global.table.category'),
          sortKey: 'Categories.name',
          sortable: 'custom',
          render: (h, params) => {
            return params.row.category.is_exempted
              ? h('span', {},[
                h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}),
                params.row.category.name
                
              ])
              : h('span', {}, params.row.category.name)
          }
        }
     
    },
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      showCategoryHelper: 'vendor/showCategoryHelper',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      complianceColor: 'vendor/riskColor'

    }),
    columns () {
      /**
       * @note createColumn -> @/mixins/tableColumnn.js
       */

      return [
        this.createColumn(this.$t('global.table.id'), 'id', 'Vendors.id'),
        this.createColumn(this.$t('global.table.name'), 'name', 'Vendors.name', row => ({
          tag: 'router-link',
          props: {tag: 'a', to: `/admin/vendors/${row.uuid}`},
          content: () => ({tag: 'span', content: row.name})
        })),
        {
          title: this.$t('global.filters.is_exempted'),
          sortable: 'custom',
          sortKey: 'Vendors.is_exempted',
          render: (h, params) => {
            const render = h('Tag', {
              props: {
                color: 'blue'
              }
            }, 'Exempted')
            return params.row.is_exempted === 1 ? render : ''
          }
        },
        this.doesHelperColumn(),
        // this.createColumn(this.$t('global.table.category'), 'category.name', 'Categories.name', 'underQualification'),

        this.createColumn(this.$t('global.table.tcf'), 'iabv2Id', 'Vendors.iabv2Id', null, 100),

        // this.createColumn(this.$t('global.filters.is_exempted'), 'is_exempted', 'Vendors.is_exempted', row => ({
        //   content: (params) => ({tag: 'span',})
        // }), 100),
        this.createColumn(this.$t('global.table.country'), 'country.displayName', 'Countries.name', row => ({
          content: (row.country && row.country.displayName) ? row.country.displayName : 'Under qualification'
        })),
        this.createColumn(this.$t('global.table.created'), 'created', 'Vendor.created', 'date'),
        this.createColumn(this.$t('global.table.modified'), 'modified', 'Vendors.modified', 'date'),
        this.createColumn(this.$t('global.table.manage'), null, null, row => ({
          tag: 'router-link',
          props: {tag: 'a', to: `/admin/vendors/${row.uuid}`},
          content: () => ({tag: 'Button', props: {type: 'primary'}, classes: 'btn btn--elevated', content: 'Manage'})
        }))
      ]
    }
  }
}
</script>
